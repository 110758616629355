// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Utils
import { _localizeTime } from "utils";

// Style
import Style from "../style.module.scss";

// Components
import { CartItem } from "components";

// Fields
import { ResponsiveModal } from "fields";

// Assets
import { ChevronArrowIcon } from "assets/Icons";

// Lib
import { DATE_FORMAT, TIME_FORMAT } from "lib/_constants";

class Items extends React.Component {
  state = {};

  renderItemsModal = (items_list) => (
    <ResponsiveModal
      classNames={{ modal: Style.modal }}
      contentLabel="Order Details modal"
      headerProps={{
        header: `Order Details #${this.props.order_id}`,
        show_back_button: true,
        show_close_button: false,
      }}
      onCloseModal={() => this.setState({ show_items: false })}
    >
      {Object.entries(items_list).map((entry) => (
        <div key={entry[0]}>
          <CartItem
            detailsOfItemInCart={entry[1]}
            idOfItemInCart={entry[0]}
            showRemoveIcon={false}
          />
        </div>
      ))}
    </ResponsiveModal>
  );

  renderOrderStatus = () => {
    const { courier, delivery_type, order_status } = this.props;
    const class_name = cx(Style.order_status, Style[order_status]);
    switch (order_status) {
      case "active":
        return (
          <div className={class_name}>Waiting for restaurant to accept</div>
        );
      case "confirmed":
        return <div className={class_name}>Accepted by restaurant</div>;
      case "closed":
        return (
          <div className={class_name}>
            Ready for pickup by{" "}
            {delivery_type === "deliver" ? (
              <span style={{ textTransform: "capitalize" }}>{courier}</span>
            ) : (
              "customer"
            )}
          </div>
        );
      default:
        return;
    }
  };

  render() {
    const {
      items_list,
      order_id,
      order_submitted_at,
      shop_time_zone,
    } = this.props;
    const itemsCount = Object.keys(items_list).reduce((result, id) => {
      const { quantity } = items_list[id];
      return result + quantity;
    }, 0);
    const submitted_at = _localizeTime({
      time: order_submitted_at,
      time_format: `${DATE_FORMAT} ${TIME_FORMAT}`,
      time_zone: shop_time_zone,
    });

    return (
      <>
        {this.state.show_items && this.renderItemsModal(items_list)}
        <div onClick={() => this.setState({ show_items: true })}>
          <div>
            <h2 className={Style.row_title}>Order Details</h2>
            <div className={Style.gray_text}>#{order_id}</div>
            <div className={Style.gray_text}>
              {itemsCount} item{itemsCount > 1 ? "s" : ""}
            </div>
            <div className={Style.gray_text}>{submitted_at}</div>
            {this.renderOrderStatus()}
          </div>
          <div className={Style.arrow}>
            <ChevronArrowIcon />
          </div>
        </div>
      </>
    );
  }
}

Items.propTypes = {
  courier: PropTypes.string,
  delivery_type: PropTypes.oneOf(["inStore", "pickUp", "deliver"]).isRequired,
  items_list: PropTypes.object.isRequired,
  order_id: PropTypes.string.isRequired,
  order_status: PropTypes.oneOf(["active", "confirmed", "closed"]).isRequired,
  order_submitted_at: PropTypes.string.isRequired,
  shop_time_zone: PropTypes.string.isRequired,
};

export default Items;
