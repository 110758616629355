// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import Img from "react-cool-img";
import _isEmpty from "lodash.isempty";

// Style
import Style from "../style.module.scss";

// Fields
import { Link } from "fields";

// Assets
import { PhoneIcon } from "assets/Icons";

const ShopInfo = ({ address, name, logoURL, phoneNumber, shop_id }) => (
  <div>
    <h2 className={Style.row_title}>Store</h2>
    <div className={Style.gray_text}>{name}</div>
    <div className={Style.gray_text}>#{shop_id}</div>
    <div className={Style.gray_text}>{address}</div>
    {logoURL && (
      <Img
        alt="shop logo"
        className={Style.driver_profile_image}
        src={logoURL}
      />
    )}
    {!_isEmpty(phoneNumber) && (
      <Link href={`tel:${phoneNumber}`} target="_self">
        <div className={Style.icon}>
          <PhoneIcon />
        </div>
      </Link>
    )}
  </div>
);

ShopInfo.propTypes = {
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logoURL: PropTypes.string,
  phoneNumber: PropTypes.string,
  shop_id: PropTypes.string.isRequired,
};

export default ShopInfo;
