// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

import ShopInfo from "./ShopInfo";
import DesktopCategoryNavigation from "./DesktopCategoryNavigation";

// Style
import Style from "./style.module.scss";

// Components
import ContactUs from "components/WebsiteBuilder/ContactUs";
import SkipliFooter from "components/WebsiteBuilder/SkipliFooter";

// Fields
import { ScreenSize } from "fields";

class PageContentContainer extends React.Component {
  state = {};

  renderMobileContent = () => (
    <div>
      <ShopInfo
        onChangeOrderFulfillmentMethod={
          this.props.onChangeOrderFulfillmentMethod
        }
        order_fulfillment_method={this.props.order_fulfillment_method}
        shopBasicInfo={this.props.shopBasicInfo}
        shop_id={this.props.shop_id}
        todayHours={this.props.todayHours}
      />
      <div className={Style.customerMobile}>{this.props.children}</div>
      {/* {!this.props.isLoadingCustomerMenus && (
        <React.Fragment>
          <ContactUs shopBasicInfo={this.props.shopBasicInfo} />
          <SkipliFooter />
        </React.Fragment>
      )} */}
    </div>
  );

  renderContent = () => {
    const { children, menuInfo, shopBasicInfo, shop_id } = this.props;
    const { groups = {} } = menuInfo;

    return (
      <React.Fragment>
        <ScreenSize deviceType="mobile">
          {this.renderMobileContent()}
        </ScreenSize>
        <ScreenSize deviceType="tablet">
          {this.renderMobileContent()}
        </ScreenSize>
        <ScreenSize deviceType="desktop">
          <div>
            <div className={Style.customerDesktop}>
              <ShopInfo
                onChangeOrderFulfillmentMethod={
                  this.props.onChangeOrderFulfillmentMethod
                }
                order_fulfillment_method={this.props.order_fulfillment_method}
                shopBasicInfo={shopBasicInfo}
                shop_id={shop_id}
                todayHours={this.props.todayHours}
              />
              <DesktopCategoryNavigation
                categoriesVisibility={this.props.categoriesVisibility}
                groups={groups}
                onScrollToCategory={this.props.onScrollToCategory}
              />
              <div>{children}</div>
            </div>
            <ContactUs shopBasicInfo={this.props.shopBasicInfo} />
            <SkipliFooter />
          </div>
        </ScreenSize>
      </React.Fragment>
    );
  };

  render() {
    return this.renderContent();
  }
}

PageContentContainer.propTypes = {
  categoriesVisibility: PropTypes.object,
  children: PropTypes.any,
  isLoadingCustomerMenus: PropTypes.bool,
  menuInfo: PropTypes.shape({
    groups: PropTypes.object,
    menuName: PropTypes.string,
  }),
  onChangeOrderFulfillmentMethod: PropTypes.func.isRequired,
  onScrollToCategory: PropTypes.func.isRequired,
  order_fulfillment_method: PropTypes.oneOf([
    "catering",
    "inStore",
    "pickUp",
    "deliver",
  ]).isRequired,
  shopBasicInfo: PropTypes.object.isRequired,
  shop_id: PropTypes.string.isRequired,
  todayHours: PropTypes.shape({
    isShopClosed: PropTypes.bool,
    shopTodayOpenHours: PropTypes.object,
    today: PropTypes.oneOf([
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ]),
  }),
};

PageContentContainer.defaultProps = {
  categoriesVisibility: {},
  isLoadingCustomerMenus: false,
  menuInfo: {},
};

export default PageContentContainer;
