// BUTI DINERS, INC. All right Reserved ©

// Lib
import { RegEx, STRIPE } from "lib/_constants";
import { CartFuncs, Math } from "lib/functions";

const MAX_DELIVERY_FEE = 8;

const { STRIPE_TRANSACTION_FEES } = STRIPE;
const { CONV_FEE } = STRIPE_TRANSACTION_FEES;
const { CalculateServiceFee } = CartFuncs;

const _calculateOrderTotalAmount = ({
  orderDeliveryTypeID = "pickUp",
  shop_id,
  subTotal = 0,
  subTotalWithTax = 0,
  tipAmount = 0,
  totalDeliveryFee = 0,
}) => {
  const sum =
    subTotalWithTax +
    tipAmount +
    CONV_FEE +
    CalculateServiceFee({ orderDeliveryTypeID, shop_id, subTotal }) +
    (orderDeliveryTypeID === "deliver" ? totalDeliveryFee : 0);
  return parseFloat(Math._roundNumber(sum));
};

const _isTipAmountValid = (tipAmount) =>
  String(tipAmount).match(/^[0-9]*\.?([0-9]|[0-9][0-9])$/);

const _getSubmitButtonStatus = (params) => {
  const {
    courierQuotes = {},
    customerName = "",
    orderDeliveryTypeID = "inStore",
    phoneNumber = "",
    pickUpTime = "",
    selectedPaymentMethodFingerprint = "",
    tableNumber = "",
    tipAmount = 0,
    selectedPaymentWalletOption = "",
  } = params;
  if (!customerName) return "inactive";
  if (!phoneNumber || !RegEx.US_PHONE_NUMBER.test(phoneNumber))
    return "inactive";
  if (orderDeliveryTypeID === "inStore") {
    if (!tableNumber) return "inactive";
  }
  if (orderDeliveryTypeID === "pickUp") {
    if (!pickUpTime) return "inactive";
  }
  if (orderDeliveryTypeID === "deliver") {
    const { doordash = {} } = courierQuotes;
    const { totalDeliveryFee = 0 } = doordash;
    if (
      parseFloat(totalDeliveryFee) <= 0 ||
      parseFloat(totalDeliveryFee) > MAX_DELIVERY_FEE
    )
      return "inactive";
  }
  if (selectedPaymentWalletOption === "card") {
    if (!selectedPaymentMethodFingerprint) return "inactive";
  }
  if (tipAmount) {
    if (!_isTipAmountValid(tipAmount)) return "inactive";
  }
  return "active";
};

export { MAX_DELIVERY_FEE, _calculateOrderTotalAmount, _getSubmitButtonStatus };
