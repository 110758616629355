// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Style
import Style from "./style.module.scss";

// Lib
import { FoodMenuFuncs } from "lib/functions";

class DesktopCategoryNavigation extends React.Component {
  state = { groupsVisibility: {} };

  onGetFirstVisibleCategoryID = () => {
    const { categoriesVisibility = {} } = this.props;
    return parseInt(
      Object.keys(categoriesVisibility).reduce((result, categoryIndex) => {
        const isVisible = categoriesVisibility[categoryIndex];
        if (result < 0) return isVisible ? categoryIndex : result;
        return result;
      }, -1)
    );
  };

  renderContent = (categories = {}) => {
    const { _sortMenuCategories } = FoodMenuFuncs;
    const firstVisibleCategoryIndex = this.onGetFirstVisibleCategoryID();
    return _sortMenuCategories(categories).map((categoryId, categoryIndex) => {
      const { groupIsArchived = false, groupName, items = {} } = categories[
        categoryId
      ];
      if (Object.keys(items).length === 0 || groupIsArchived) return null;
      return (
        <button
          className={cx(
            Style.category,
            firstVisibleCategoryIndex === categoryIndex && Style.selected
          )}
          key={categoryId}
          onClick={() => this.props.onScrollToCategory(categoryId)}
        >
          {groupName}
        </button>
      );
    });
  };

  render() {
    const { groups: categories = {} } = this.props;
    return (
      Object.keys(categories).length > 0 && (
        <div className={Style.container}>
          <div className={Style.content}>{this.renderContent(categories)}</div>
        </div>
      )
    );
  }
}

DesktopCategoryNavigation.propTypes = {
  categoriesVisibility: PropTypes.object,
  groups: PropTypes.object,
  onScrollToCategory: PropTypes.func.isRequired,
};

DesktopCategoryNavigation.defaultProps = {
  categoriesVisibility: {},
  groups: {},
};

export default DesktopCategoryNavigation;
