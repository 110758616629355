// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Utils
import { _formatPhoneNumber } from "utils";

// Style
import Style from "../style.module.scss";

const GuestInfo = ({
  customerName,
  deliveryDetails = {},
  orderDeliveryTypeID = "pickUp",
  phoneNumber,
  tableNumber,
  uuid,
}) => {
  const { deliveryAddress: delivery_address = {}, deliveryNotes = "" } =
    deliveryDetails || {};
  const { city, state, streetAddress1, streetAddress2, zip } = delivery_address;

  const guest_address = streetAddress2
    ? [streetAddress1, streetAddress2, city, state, zip].join(", ")
    : [streetAddress1, city, state, zip].join(", ");

  return (
    <div>
      <h2 className={Style.row_title}>Customer</h2>
      <div className={Style.gray_text}>{customerName}</div>
      <div className={Style.gray_text}>#{uuid}</div>
      <div className={Style.gray_text}>{_formatPhoneNumber(phoneNumber)}</div>
      {orderDeliveryTypeID === "deliver" && (
        <div className={Style.delivery_address}>{guest_address}</div>
      )}
      {orderDeliveryTypeID === "inStore" && (
        <div className={Style.delivery_address}>Table #{tableNumber}</div>
      )}
      {orderDeliveryTypeID === "pickUp" && (
        <div className={Style.pickup_notice}>
          You will pick up the order at the store
        </div>
      )}
      {deliveryNotes && (
        <div className={Style.gray_text}>"{deliveryNotes}""</div>
      )}
    </div>
  );
};

GuestInfo.propTypes = {
  customerName: PropTypes.string.isRequired,
  deliveryDetails: PropTypes.shape({
    deliveryAddress: PropTypes.shape({
      streetAddress1: PropTypes.string.isRequired,
      streetAddress2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }),
    deliveryNotes: PropTypes.string,
  }),
  orderDeliveryTypeID: PropTypes.oneOf(["deliver", "inStore", "pickUp"])
    .isRequired,
  phoneNumber: PropTypes.string.isRequired,
  tableNumber: PropTypes.string,
  uuid: PropTypes.string.isRequired,
};

export default GuestInfo;
