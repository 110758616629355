/* eslint-disable jsx-a11y/anchor-has-content */
// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash.isempty";
import dayjs from "dayjs";
import cx from "classnames";

import { _sortSpecialHours } from "./functions";

// Icons
import { ClockIcon, PhoneIcon, LocationIcon } from "assets/Icons";

// Utils
import { _formatPhoneNumber } from "utils";

// Components
import { ShopHoursTable } from "components";

// Fields
import { Link, ResponsiveModal } from "fields";

// Style
import Style from "./style.module.scss";
import OrderingPageMainMenuStyle from "components/Modals/OrderingPageMainMenu/style.module.scss";

class AboutShop extends React.Component {
  renderAllOpenHours = () => {
    const { shop_basic_info = {}, today_hours = {} } = this.props;
    const { openHours = {}, timeZone } = shop_basic_info;
    const { today } = today_hours;
    return (
      <div className={Style.hoursContainer}>
        <ShopHoursTable hours={openHours} timeZone={timeZone} today={today} />
      </div>
    );
  };

  renderSpecialHours = ({ special_hours }) => {
    const { today_hours = {} } = this.props;
    const { today_date = "" } = today_hours;
    return _sortSpecialHours({ special_hours }).map((id) => {
      const { date, end_at, is_closed = true, start_at } = special_hours[id];
      return (
        <div
          className={cx(
            Style.special_hour_container,
            today_date === date && Style.isToday
          )}
          key={id}
        >
          <div className={Style.special_hour_date}>{date}</div>
          <div>
            {is_closed ? (
              "Closed"
            ) : (
              <div className={Style.time_pickers_group}>
                <div>{dayjs("1/1/1 " + start_at).format("h:mm A")}</div>
                <div style={{ margin: "0 4px" }}>-</div>
                <div>{dayjs("1/1/1 " + end_at).format("h:mm A")}</div>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  renderContent = () => {
    const { shop_basic_info = {} } = this.props;
    const {
      address = "",
      logoURL = "",
      name = "",
      phoneNumber,
      special_hours = {},
    } = shop_basic_info;
    const formattedAddress = address.split(" ").join("+");
    return (
      <div>
        <div className={Style.logoName}>
          {logoURL && <img alt="" src={logoURL} />}
          <h1>{name}</h1>
        </div>
        <div className={Style.infoContainer}>
          <LocationIcon className={Style.icon} />
          <div>
            <Link
              className={Style.link}
              href={`https://google.com/maps/place/${formattedAddress}`}
            >
              {address}
            </Link>
          </div>
        </div>
        {phoneNumber && (
          <div className={Style.infoContainer}>
            <PhoneIcon className={Style.icon} />
            <div>
              <Link
                className={Style.link}
                href={`tel: ${phoneNumber}`}
                target="_self"
              >
                {_formatPhoneNumber(phoneNumber)}
              </Link>
            </div>
          </div>
        )}
        {!_isEmpty(special_hours) && (
          <React.Fragment>
            <div className={Style.infoContainer}>
              <ClockIcon className={Style.icon} />
              Special Hours
            </div>
            <div
              className={Style.hoursContainer}
              style={{ marginBottom: "1.2rem" }}
            >
              {this.renderSpecialHours({ special_hours })}
            </div>
          </React.Fragment>
        )}
        <div className={Style.infoContainer}>
          <ClockIcon className={Style.icon} />
          Open Hours
        </div>
        {this.renderAllOpenHours()}
        {/* {this.renderContactBar()} */}
      </div>
    );
  };

  render() {
    return (
      <ResponsiveModal
        classNames={{ modal: OrderingPageMainMenuStyle.modal }}
        contentLabel="About shop"
        headerProps={{ show_back_button: true, show_close_button: false }}
        onCloseModal={this.props.onCloseModal}
      >
        {this.renderContent()}
      </ResponsiveModal>
    );
  }
}

AboutShop.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  shop_basic_info: PropTypes.shape({
    address: PropTypes.string,
    logoURL: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  today_hours: PropTypes.object,
};

export default AboutShop;

// onShare = () => {
//   if (navigator.share) {
//     const { shopBasicInfo = {} } = this.props;
//     const { name = "" } = shopBasicInfo;
//     navigator
//       .share({
//         title: "See what I found!",
//         text: `Hey! You won't have to wait in line at ${name} by ordering through SKIPLI. Simply scan the QR code to order and pay. https://butidiners.com.`,
//       })
//       .then(() => console.log("Successful share"))
//       .catch((error) => console.log("Error sharing", error));
//   }
// };

// renderShareMessage = () => {
//   const msg = "Hey!";
//   return msg.split(" ").join("%20");
// }

//   <a className={Style.button} href={`sms://&body=${this.renderShareMessage()}`}>
//   <SpeechBubblesIcon className={Style.icon} />
// </a>

// renderContactBar = () => {
//   const { shopBasicInfo = {} } = this.props;
//   const { address = "" } = shopBasicInfo;
//   const formattedAddress = address.split(" ").join("+");
//   return (
//     <div className={Style.contactBar}>
//       <div className={Style.item}>
//         <button className={Style.button}>
//           <PhoneIcon className={Style.icon} />
//         </button>
//         Call
//       </div>
//       <div className={Style.item}>
//         <Link
//           className={Style.button}
//           href={`https://google.com/maps/place/${formattedAddress}`}
//         >
//           <NavigationIcon className={Style.icon} />
//         </Link>
//         Directions
//       </div>
//       <div className={Style.item}>
//         <button className={Style.button} onClick={this.onShare}>
//           <SpeechBubblesIcon className={Style.icon} />
//         </button>
//         Invite Friends
//       </div>
//     </div>
//   );
// };
