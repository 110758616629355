// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash.isempty";

import DriverInfo from "./DriverInfo";
import GuestInfo from "./GuestInfo";
import Items from "./Items";
import ShopInfo from "./ShopInfo";

// Style
import Style from "./style.module.scss";

class OrderTrackingOrderDetails extends React.Component {
  state = {};

  render() {
    const {
      courier_delivery_details = {},
      order_details = {},
      order_id,
      shop_basic_info = {},
      shop_id,
    } = this.props;

    const {
      deliveryDetails = {},
      orderDeliveryTypeID,
      orderItems = {},
      status,
      timeStamp,
    } = order_details;
    const { courier = "" } = deliveryDetails;
    const { timeZone } = shop_basic_info;

    return (
      <div>
        <div className={Style.row}>
          <ShopInfo {...shop_basic_info} shop_id={shop_id} />
        </div>
        {!_isEmpty(courier_delivery_details) && (
          <div className={Style.row}>
            <DriverInfo {...courier_delivery_details} />
          </div>
        )}
        <div className={Style.row}>
          <Items
            courier={courier}
            delivery_type={orderDeliveryTypeID}
            items_list={orderItems}
            order_id={order_id}
            order_status={status}
            order_submitted_at={timeStamp}
            shop_time_zone={timeZone}
          />
        </div>
        <div className={Style.row}>
          <GuestInfo {...order_details} />
        </div>
      </div>
    );
  }
}

OrderTrackingOrderDetails.propTypes = {
  courier_delivery_details: PropTypes.object,
  order_details: PropTypes.object.isRequired,
  order_id: PropTypes.string.isRequired,
  shop_basic_info: PropTypes.object.isRequired,
  shop_id: PropTypes.string.isRequired,
};

export default OrderTrackingOrderDetails;
