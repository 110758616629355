// BUTI DINERS, INC. All right Reserved ©

import CustomerInfo from "./CustomerInfo";
import DeliveryInfo from "./DeliveryInfo";
import DeliveryQuotes from "./DeliveryQuotes";
import DeliveryTypeSelector from "./DeliveryTypeSelector";
import NewDeliveryFeature from "./NewDeliveryFeature";
import PaymentRequestButtonStripe from "./PaymentRequestButtonStripe";
import PaymentWalletSelector from "./PaymentWalletSelector";
import PickupInfo from "./PickupInfo";
import StoredPaymentMethods from "./StoredPaymentMethods";
import SubmitButtons from "./SubmitButtons";
import TipAmountSelector from "./TipAmountSelector";

const ConfirmOrderModules = {
  CustomerInfo,
  DeliveryInfo,
  DeliveryQuotes,
  DeliveryTypeSelector,
  NewDeliveryFeature,
  PaymentRequestButtonStripe,
  PaymentWalletSelector,
  PickupInfo,
  StoredPaymentMethods,
  SubmitButtons,
  TipAmountSelector,
};

export default ConfirmOrderModules;
