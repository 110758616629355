// BUTI DINERS, INC. All right Reserved ©

import _orderBy from "lodash.orderby";
import _get from "lodash.get";

const _sortMenuItems = ({ items = {} }) =>
  _orderBy(
    Object.keys(items),
    [
      (item_id) => {
        const value = _get(items, `${item_id}.itemIsOutOfStock.true`);
        return value === "checked" ? 0 : 1;
      },
      (item_id) => _get(items, `${item_id}.itemName`) || "",
    ],
    ["desc", "asc"]
  );

export { _sortMenuItems };
